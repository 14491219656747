import {
  CustomGroup,
  CustomGroupWithProducts,
  CustomGroupsState,
} from "@typedef/customGroups";
import { TAG_TYPES, api } from "../apiSlice";
import { setError, setInfoMessage } from "../globalToast.redux";

import { DATETIME_PERIODS } from "../utils/dateTimeUtils";
import { Dispatch } from "redux";
import { Range } from "@typedef/store";
import axios from "axios";
import { baseUrl } from "../../configs";
import get from "lodash/get";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";

export const SEARCH_PRODUCTS_FETCHING = "SEARCH_PRODUCTS_FETCHING";
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";

const initState: CustomGroupsState = {
  products: { fetching: false },
};

type SearchProductsFetchingAction = {
  type: typeof SEARCH_PRODUCTS_FETCHING;
};
type SearchProductsAction = {
  type: typeof SEARCH_PRODUCTS;
  payload: { products: { data: any[]; count: number } };
};

type CustomGroupsAction = SearchProductsFetchingAction | SearchProductsAction;

export const customGroups = (
  state: CustomGroupsState = initState,
  action: CustomGroupsAction
): CustomGroupsState => {
  switch (action.type) {
    case SEARCH_PRODUCTS_FETCHING:
      return {
        ...state,
        products: {
          ...state.products,
          fetching: true,
        },
      };

    case SEARCH_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          ...action.payload,
          fetching: false,
        },
      };

    default:
      return state;
  }
};

export interface SearchProductsArgs {
  mid: string;
  pageSize: number;
  searchText: string;
  products?: string[];
  excludeInactive?: boolean;
  shouldSearchByParentSku?: boolean;
}

export const searchProducts =
  ({
    mid,
    pageSize,
    searchText,
    products,
    excludeInactive,
    shouldSearchByParentSku,
  }: SearchProductsArgs) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: SEARCH_PRODUCTS_FETCHING });
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/generic-mws-service/api/customGroups/searchProducts`,
        {
          mid,
          pageSize,
          searchText,
          products,
          excludeInactive,
          shouldSearchByParentSku: shouldSearchByParentSku ?? false,
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: SEARCH_PRODUCTS,
          payload: data,
        });
      } else {
        return setError(dispatch, data.error, status, "customGroupSearch");
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status"),
        "customGroupSearch"
      );
    }
  };

interface CustomGroupsArgs {
  mid: string;
  userId: string;
}
interface CustomGroupsResponse {
  groups: CustomGroup[];
}

interface CustomGroupMetricsArgs {
  mid: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  includeTax: boolean;
  selectedGroups: CustomGroup[];
  pageIndex: number;
  pageSize: number;
  searchText?: string;
}

export interface CustomGroupsMetrics {
  groupId: number;
  groupName: string;
  productCount: string;
  avPrice: number;
  unitsSold: number;
  outOfStockPercentage: number;
  totalSales: number;
  adSpend: number;
  adSales: number;
  clicks: number;
  adUnits: number;
  impressions: number;
  clickThroughRate: number;
  conversionRate: number;
  acos: number;
  roas?: number;
  tacos: number;
  troas?: number;
  priorSales: number;
  priorUnits: number;
  priorPrice: number;
  currency: string;
  filteredStores?: string[];
}

interface CustomGroupMetricsResponse {
  data: CustomGroupsMetrics[];
  count: number;
}

interface CustomGroupsChartArgs {
  mid: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  includeTax: boolean;
  type: string;
  selectedGroups: CustomGroup[];
}

export interface CustomGroupsChartData {
  date: number;
  groups: { groupId: string; name: string; value: number }[];
}

interface CustomGroupsChartResponse {
  chart: {
    data?: CustomGroupsChartData[];
    currency?: string;
  };
  totals: {
    current?: number;
    prior?: number;
  };
}

interface CustomVendorGroupMetricsArgs {
  mid: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  includeTax: boolean;
  selectedGroups: CustomGroup[];
  pageIndex: number;
  pageSize: number;
  searchText?: string;
}

export interface VendorGroupMetrics {
  groupId: number;
  groupName: string;
  productCount: string;
  isFulfilledByAmazon: boolean;
  brand: string;
  categoryLabel: string;
  parentCategory: string;
  shippedCogs: number;
  shippedCogsManufacturing: number;
  shippedUnits: number;
  orderedRevenue: number;
  glanceViews: number;
  orderedUnits: number;
  price: number;
  unitsPerView: number;
  priorShippedCogs: number;
  priorShippedCogsManufacturing: number;
  priorShippedUnits: number;
  priorOrderedRevenue: number;
  priorGlanceViews: number;
  priorOrderedUnits: number;
  priorPrice: number;
  priorUnitsPerView: number;
  shippedUnitsManufacturing: number;
  priorShippedUnitsManufacturing: number;
  delta: number;
  tacos: number;
}

interface CustomVendorGroupMetricsResponse {
  data: VendorGroupMetrics[];
  count: number;
}

interface VendorCustomGroupsChartArgs {
  mid: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  includeTax: boolean;
  type: string;
  selectedGroups: CustomGroup[];
}

interface VendorCustomGroupsChartResponse {
  chart: {
    data?: CustomGroupsChartData[];
    currency?: string;
  };
  totals: {
    current?: number;
    prior?: number;
  };
}

interface FetchCustomGroupArgs {
  mid: string;
  groupId: number;
}

interface FetchCustomGroupResponse {
  group: CustomGroupWithProducts;
}

interface SearchBrandsArgs {
  mid: string;
  pageSize: number;
  searchText: string;
  excludeInactive?: boolean;
}

interface SearchBrandsResponse {
  brands: string[];
}

interface CreateGroupByBrandArgs {
  mid: string;
  groupName: string;
  brands: string[];
  groupId?: number;
  successMessage: string;
}

interface UploadCustomGroupsFileResponse {
  result: {
    status: string;
    productsNotFound?: string[];
    message?: string;
  };
}

interface UploadCustomGroupsFileParams {
  mid: string;
  successMessage: string;
  inProgressMessage: string;
  file: File;
}

interface DeleteGroupArgs {
  mid: string;
  groupId: number;
  successMessage: string;
}

interface CreateGroupByProductArgs {
  mid: string;
  groupName: string;
  products: any[];
  groupId?: number;
  successMessage: string;
}

interface UngroupedProductCountArgs {
  userId: string;
  mid: string;
}

interface UngroupedProductCountResponse {
  count: number;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    customGroups: build.query<CustomGroupsResponse, CustomGroupsArgs>({
      query: (params) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/customGroups`,
          method: "GET",
          params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("customGroups"),
      providesTags: [TAG_TYPES.CustomGroups],
    }),

    customGroup: build.query<FetchCustomGroupResponse, FetchCustomGroupArgs>({
      query: (params) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/customGroups/group`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("CustomGroup"),
      providesTags: [TAG_TYPES.CustomGroups],
    }),

    ungroupedProductCount: build.query<
      UngroupedProductCountResponse,
      UngroupedProductCountArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/customGroups/ungroupedProductCount`,
          method: "GET",
          params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("UngroupedProductCount"),
      providesTags: [TAG_TYPES.CustomGroups],
    }),

    customGroupMetrics: build.query<
      CustomGroupMetricsResponse,
      CustomGroupMetricsArgs
    >({
      query: (params) => {
        const { currentRange, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/customGroups/metrics`,
          method: "POST",
          data: {
            ...otherParams,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler("customGroupMetrics"),
      providesTags: [TAG_TYPES.CustomGroups],
    }),

    customGroupChart: build.query<
      CustomGroupsChartResponse,
      CustomGroupsChartArgs
    >({
      query: (params) => {
        const { currentRange, type, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/customGroups/${type}`,
          method: "POST",
          data: {
            ...otherParams,
            ...currentRange,
            type,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler("customGroupsChart"),
      providesTags: [TAG_TYPES.CustomGroups],
    }),

    customVendorGroupMetrics: build.query<
      CustomVendorGroupMetricsResponse,
      CustomVendorGroupMetricsArgs
    >({
      query: (params) => {
        const { currentRange, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/vendor/revenueByGroup`,
          method: "POST",
          data: {
            ...otherParams,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler("customVendorGroupMetrics"),
      providesTags: [TAG_TYPES.CustomGroups],
    }),

    vendorCustomGroupChart: build.query<
      VendorCustomGroupsChartResponse,
      VendorCustomGroupsChartArgs
    >({
      query: (params) => {
        const { currentRange, type, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/vendor/${type}`,
          method: "POST",
          data: {
            ...otherParams,
            ...currentRange,
            type,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler("customVendorGroupsChart"),
      providesTags: [TAG_TYPES.CustomGroups],
    }),

    searchBrands: build.query<SearchBrandsResponse, SearchBrandsArgs>({
      query: (params) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/customGroups/searchBrands`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("CustomGroup/SearchBrands"),
    }),

    createOrEditGroupByBrand: build.mutation<string, CreateGroupByBrandArgs>({
      query: (params) => {
        const { mid, groupName, brands, groupId } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/customGroups/createGroupByBrands`,
          method: "POST",
          data: {
            mid,
            groupName,
            brands,
            groupId,
          },
        };
      },
      invalidatesTags: [TAG_TYPES.CustomGroups],
      onQueryStarted: globalQueryErrorHandler(
        "CreateOrEditGroupByBrand",
        false,
        (_data: string, dispatch, args) => {
          setInfoMessage(dispatch, args.successMessage);
        }
      ),
    }),

    uploadCustomGroupsFile: build.mutation<
      UploadCustomGroupsFileResponse,
      UploadCustomGroupsFileParams
    >({
      query: (params) => {
        const { mid, file } = params;
        const formData = new FormData();

        formData.append("mid", mid);
        formData.append("file", file);

        return {
          url: `${baseUrl}/api/myStores/customGroups/csv`,
          method: "POST",
          data: formData,
        };
      },
      invalidatesTags: [TAG_TYPES.CustomGroups],
      onQueryStarted: globalQueryErrorHandler(
        "UploadCustomGroupsFile",
        false,
        (data: UploadCustomGroupsFileResponse, dispatch, args) => {
          if (data?.result?.status === "inProgress") {
            setInfoMessage(dispatch, args.inProgressMessage);
          } else {
            setInfoMessage(dispatch, args.successMessage);
          }
        }
      ),
    }),

    deleteCustomGroup: build.mutation<string, DeleteGroupArgs>({
      query: (params) => {
        const { mid, groupId } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/customGroups/deleteGroup`,
          method: "DELETE",
          data: {
            mid,
            groupId,
          },
        };
      },
      invalidatesTags: [TAG_TYPES.CustomGroups],
      onQueryStarted: globalQueryErrorHandler(
        "DeleteCustomGroup",
        false,
        (_data: string, dispatch, args) => {
          setInfoMessage(dispatch, args.successMessage);
        }
      ),
    }),

    createOrEditCustomGroupByProducts: build.mutation<
      string,
      CreateGroupByProductArgs
    >({
      query: (params) => {
        const { mid, groupName, products, groupId } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/customGroups/createGroup`,
          method: "POST",
          data: {
            mid,
            groupName,
            products,
            groupId,
          },
        };
      },
      invalidatesTags: [TAG_TYPES.CustomGroups],
      onQueryStarted: globalQueryErrorHandler(
        "CreateOrEditGroupByProducts",
        false,
        (_data: string, dispatch, args) => {
          setInfoMessage(dispatch, args.successMessage);
        }
      ),
    }),
  }),
});

export const {
  useCustomGroupsQuery,
  useCustomGroupQuery,
  useUngroupedProductCountQuery,
  useCustomGroupMetricsQuery,
  useCustomGroupChartQuery,
  useCustomVendorGroupMetricsQuery,
  useVendorCustomGroupChartQuery,
  useSearchBrandsQuery,
  useCreateOrEditGroupByBrandMutation,
  useUploadCustomGroupsFileMutation,
  useDeleteCustomGroupMutation,
  useCreateOrEditCustomGroupByProductsMutation,
} = extendedApiSlice;
