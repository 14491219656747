import { Box, IconButton } from "@material-ui/core";
import {
  CellAccessor,
  CellType,
  FilterBy,
  FilterTypes,
  PrunedAMCReportType,
} from "~/typedef/amc/reportType";
import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TextCell, WrappedTextCell } from "~/components/table/cells/textCell";
import {
  formatCurrencyRounded,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";
import {
  getPTCTileColor,
  pathToConversionPathRegex,
} from "./helpers/pathToConversion";
import {
  useGetCampaignGroupsQuery,
  useReportRunDataQuery,
  useReportRunDownloadUrlQuery,
} from "~/store/mystore/amc.redux";

import { AMCCSVRows } from "~/typedef/amc/amcReport";
import { CurrentStore } from "~/typedef/store";
import MultiSelect from "~/components/select/multiSelectDropDown";
import { PaginationArgs } from "~/typedef/pagination";
import Panel from "~/components/panel/panel";
import { PathCell } from "~/components/table/cells/pathCell";
import { SmallDownloadCSVIcon } from "../reportDownload/downloadCsv";
import Table from "~/components/adTable/table";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { getPercentageDifference } from "~/utils/salesUtils";
import moment from "moment";
import { numberWithCommas } from "~/utils/utils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface GenericReportTableProps {
  store: CurrentStore;
  reportType: PrunedAMCReportType;
  currentCurrency: string;
  pageSize: number;
  currentReportRunId: string;
  priorReportRunId?: string;
  conditionalFormatting?: boolean;
  report?: boolean;
}

interface GenericReportRow {
  current: AMCCSVRows;
  prior?: AMCCSVRows;
}

const DEFAULT_SORT_KEY = "";
const DEFAULT_SORT_ORDER = "desc";
const DEFAULT_BADGE_WIDTH = 55;

const getCellType = (cellType?: CellType) => {
  switch (cellType) {
    case CellType.PathCell:
      return PathCell;
    case CellType.ValueAndGrowthCell:
      return ValueAndGrowthCell;
    case CellType.TextCell:
      return TextCell;
    default:
      return WrappedTextCell;
  }
};

const getPrimaryKeyValue = (row: AMCCSVRows, primaryKey: string[]) => {
  return primaryKey.map((key) => row[key] ?? "undefined").join(" | ");
};

export const GenericReportTable: React.FC<GenericReportTableProps> = ({
  store,
  reportType,
  currentCurrency,
  pageSize,
  currentReportRunId,
  priorReportRunId,
  conditionalFormatting,
  report,
}) => {
  const { t } = useTranslation();
  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );
  const homeCurrency = getCurrencyByCountryCode[store.marketplaceCountry];

  const {
    columns: tableColumns,
    primaryKey: primaryKey,
    filter: reportFilters,
  } = reportType.tabular ?? { primaryKey: [], columns: [] };

  const [filterOption, setFilterOption] = useState<string[]>([]);

  const [columnSelection, setColumnSelection] = useState<Column[]>([]);

  const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
    pageIndex: 0,
    pageSize,
    sortKey: DEFAULT_SORT_KEY,
    sortOrder: DEFAULT_SORT_ORDER,
  });

  const updateFilters = useCallback(
    (values: string[]) => {
      setFilterOption(values);
      // Reset pagination
      setPaginationParams({
        ...paginationParams,
        pageIndex: 0,
      });
    },
    [setFilterOption, paginationParams, setPaginationParams]
  );

  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    setPaginationParams({
      pageIndex,
      pageSize,
      sortKey: sortBy[0]?.id || DEFAULT_SORT_KEY,
      sortOrder: sortBy[0]?.id
        ? sortBy[0]?.desc
          ? "desc"
          : "asc"
        : DEFAULT_SORT_ORDER,
    });
  }, []);

  const { currentReportData, count, isFetchingCurrentReportData } =
    useReportRunDataQuery(
      {
        reportRunId: currentReportRunId,
        paginationParams,
        searchParams:
          filterOption.length > 0 && reportFilters?.filterColumn
            ? {
                filterKey: reportFilters?.filterColumn,
                filterValue: filterOption,
              }
            : {},
      },
      {
        selectFromResult: ({ data, isFetching }) => {
          return {
            isFetchingCurrentReportData: isFetching,
            count: data?.count || 0,
            currentReportData: data?.reportData ?? [],
          };
        },
      }
    );

  const currentPeriodPrimaryKeyValues = useMemo(() => {
    return currentReportData.map((row) => getPrimaryKeyValue(row, primaryKey));
  }, [currentReportData, primaryKey]);

  const { priorReportData, isFetchingPriorReportData } = useReportRunDataQuery(
    {
      reportRunId: priorReportRunId || "",
      currentPeriodPrimaryKeyValues,
      paginationParams: {
        pageIndex: 0,
        pageSize: paginationParams.pageSize,
        sortKey: "",
        sortOrder: DEFAULT_SORT_ORDER,
      },
      searchParams: {},
    },
    {
      skip: !priorReportRunId || currentPeriodPrimaryKeyValues.length === 0,
      selectFromResult: ({ data, isFetching }) => {
        return {
          isFetchingPriorReportData: isFetching,
          priorReportData: data?.reportData ?? [],
        };
      },
    }
  );

  const { campaignGroups } = useGetCampaignGroupsQuery(
    {
      mid: store.merchantId,
    },
    {
      skip: !(reportFilters?.filterBy === FilterBy.CampaignGroups),
      selectFromResult: ({ data }) => {
        return {
          campaignGroups: data?.campaignGroups ?? [],
        };
      },
    }
  );

  const { reportDownloadUrl, isLoadingDownloadUrl } =
    useReportRunDownloadUrlQuery(
      { reportRunId: currentReportRunId },
      {
        // refetch every 9 minute
        pollingInterval: moment.duration(9, "minutes").asMilliseconds(),
        selectFromResult: ({ data, isLoading }) => ({
          isLoadingDownloadUrl: isLoading,
          reportDownloadUrl: data?.downloadUrl ?? "",
        }),
      }
    );

  const rows = useMemo<GenericReportRow[]>(() => {
    if (!currentReportData) {
      return [];
    }
    const rows = currentReportData.map((currentRow) => {
      if (
        primaryKey.length === 0 ||
        !primaryKey.every((key) => key in currentRow)
      ) {
        return {
          current: currentRow,
          prior: undefined,
        };
      }

      const currentRowPrimaryKeyValue = getPrimaryKeyValue(
        currentRow,
        primaryKey
      );
      const priorRow = priorReportData.find((row) => {
        const priorRowPrimaryKeyValue = getPrimaryKeyValue(row, primaryKey);
        return currentRowPrimaryKeyValue === priorRowPrimaryKeyValue;
      });

      return {
        current: currentRow,
        prior: priorRow,
      };
    });

    return rows;
  }, [currentReportData, priorReportData, primaryKey]);

  const getAccessor = (
    id: string,
    accessor?: CellAccessor,
    toFixed?: number,
    isPPT?: boolean,
    reverseFormatting?: boolean,
    customSuffix?: string,
    conditionalFormatting?: boolean
  ) => {
    switch (accessor) {
      case CellAccessor.PathList:
        return (row: GenericReportRow) => {
          const pathList = row.current[id]
            .toString()
            .match(pathToConversionPathRegex);
          return {
            pathList: pathList ? (pathList as string[]) : [],
            getTileColor: getPTCTileColor,
            badgeWidth: DEFAULT_BADGE_WIDTH,
          };
        };
      case CellAccessor.PercentRatio:
      case CellAccessor.Value:
        return (row: GenericReportRow) => {
          const multiplier = accessor === CellAccessor.PercentRatio ? 100 : 1;
          const currentValue = row.current[id];
          const currentValueDisplay =
            typeof currentValue === "number" && toFixed
              ? (currentValue * multiplier).toFixed(toFixed)
              : currentValue;
          const priorValue = row.prior?.[id];
          return {
            value: currentValueDisplay
              ? `${numberWithCommas(currentValueDisplay)}${customSuffix ?? ""}`
              : "-",
            growth: priorValue
              ? getPercentageDifference(currentValue, priorValue, isPPT)
              : "N/A",
            customSuffix: isPPT ? "ppt" : undefined,
            reverseFormatting,
            conditionalFormatting,
          };
        };
      case CellAccessor.Currency:
        return (row: GenericReportRow) => {
          const currentValue = row.current[id];
          const priorValue = row.prior?.[id];
          return {
            value: currentValue
              ? formatCurrencyRounded(
                  currentValue,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
            growth: priorValue
              ? getPercentageDifference(currentValue, priorValue)
              : "N/A",
            reverseFormatting,
            conditionalFormatting,
          };
        };
      default:
        return (row: GenericReportRow) => {
          const currentValue = row.current[id];
          return currentValue ? currentValue.toString() : "";
        };
    }
  };

  const columns = useMemo<Column[]>(
    () =>
      tableColumns.map((column) => ({
        Header: t(`genericAMCReportTable.${column.header}`),
        id: column.id,
        accessor: getAccessor(
          column.id,
          column.accessor,
          column.toFixed,
          column.isPPT,
          column.reverseFormatting,
          column.customSuffix,
          conditionalFormatting
        ),
        Cell: getCellType(column.cellType),
        isVisible: column.isVisible,
        align: column.align,
        cellJustify: column.cellJustify,
        divideRight: column.divideRight,
        customWidth: column.customWidth,
        disableSortBy: !column.isSortable,
      })),
    [currencyRates, currentCurrency, tableColumns]
  );

  useEffect(() => {
    if (columns.length > 0) {
      setColumnSelection(
        columns.map((column) => {
          if (
            columnSelection.find((myColumn) => myColumn.id === column.id)
              ?.isVisible === false
          ) {
            return {
              ...column,
              isVisible: false,
            };
          } else {
            return column;
          }
        })
      );
    }
  }, [columns]);

  const tableFilter = useMemo(() => {
    let options: { value: string; label: string }[] = [];
    if (reportFilters?.filterBy === FilterBy.CampaignGroups) {
      options = campaignGroups.map((group) => ({
        value: group.name,
        label: group.name,
      }));
    }

    if (reportFilters?.type === FilterTypes.MultiSelect) {
      return (
        <Box width={180}>
          <MultiSelect
            label={`amcWidget.filter.${reportFilters.label}`}
            options={options}
            currentValues={filterOption}
            handleChange={updateFilters}
          />
        </Box>
      );
    } else {
      return null;
    }
  }, [reportFilters, filterOption, campaignGroups]);

  const tableActions = useMemo(() => {
    return !report ? (
      <>
        {tableFilter}
        <ColumnSelect
          columns={columnSelection}
          setColumns={setColumnSelection}
        />
        {!isLoadingDownloadUrl && (
          <IconButton
            href={reportDownloadUrl}
            title={"Download as CSV"}
            download
          >
            <SmallDownloadCSVIcon />
          </IconButton>
        )}
      </>
    ) : undefined;
  }, [columnSelection, reportDownloadUrl, isLoadingDownloadUrl, tableFilter]);

  return (
    <Panel
      id="widget-amc-generic-table"
      title={t(`amcWidget.table.${reportType.name}Title`)}
      tooltip={t(`amcWidget.table.${reportType.name}Tooltip`)}
      actions={tableActions}
      content={
        <Table
          columns={columnSelection}
          loading={isFetchingPriorReportData || isFetchingCurrentReportData}
          data={rows}
          fetchData={fetchData}
          pagination
          pageSize={pageSize}
          pageCount={Math.ceil(count / pageSize)}
          sorting
        />
      }
    />
  );
};
