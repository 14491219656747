import { Range, MarketplaceFilters } from "~/typedef/store";

import { api } from "../apiSlice";
import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";

interface SalesByPeriodArgs {
  mid: string;
  currentRange: Range;
  marketplaceFilters: MarketplaceFilters;
  includeTax?: boolean;
  sellerSkus?: string[];
}

interface SalesByPeriod {
  startTime: number;
  endTime: number;
  sales: number;
  lineItemCount: number;
  unitsSold: number;
  avgLineItemValue: number;
}

interface SalesByPeriodResponse {
  chartData: {
    current: SalesByPeriod;
    prior: SalesByPeriod;
  }[];
  currency: string;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    salesByPeriod: build.query<SalesByPeriodResponse, SalesByPeriodArgs>({
      query: (params) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/salesByPeriod`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("SalesByPeriod"),
    }),
  }),
});

export const { useSalesByPeriodQuery } = extendedApiSlice;
