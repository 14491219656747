import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Popover,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import { ArrowDropDown } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { LinkAndImageComponent } from "~/components/table/cells/linkAndImageCell";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import SearchFilter from "~/components/adTable/searchFilter";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { searchProducts } from "~/store/customGroups/reducer.redux";
import isEmpty from "lodash/isEmpty";

const MAX_PRODUCTS = 25;
const PAGE_SIZE = 100;

const StyledTypography = styled(Typography)`
  text-decoration: underline;
  cursor: pointer;
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
  `}
`;

interface DropDownBaseProps {
  $width?: string;
}

const DropDownBase = styled(ButtonBase).withConfig({
  shouldForwardProp: (prop) => !["$width"].includes(prop),
})<DropDownBaseProps>`
  width: ${({ $width }) => $width || "100%"};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.palette.disabled.light : theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[1]};
`;

export const OptionText = styled(Typography)`
  text-align: left;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 12px;
  padding-left: 8px;
`;

export interface ProductType {
  sellerSku: string;
}

interface ProductOptionType {
  sellerSku: string;
  selected: boolean;
  productId: string;
  productSku: string;
  value: string;
}

interface ProductsSelectorProps {
  title: string;
  onProductsChange: (products: string[]) => void;
  mid: string;
  marketplace: string;
  initProducts: ProductType[];
  handleClearProducts?: () => void;
  selectAll?: boolean;
  setSelectAll?: (selectAll: boolean) => void;
}

const ProductsSelector = memo<ProductsSelectorProps>(function ProductsSelector({
  title,
  onProductsChange,
  mid,
  marketplace,
  initProducts,
  handleClearProducts,
  selectAll,
  setSelectAll,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState<ProductOptionType[]>([]);
  const [selectionInProgress, setSelectionInProgress] =
    useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] =
    useState<ProductType[]>(initProducts);

  const productsFetching = useTypedSelector(
    (state) => state?.customGroups?.products?.fetching || false
  );
  const productsData = useTypedSelector(
    (state) => state?.customGroups?.products?.data || []
  );

  const fetchProducts = useCallback(() => {
    dispatch(
      searchProducts({
        mid,
        pageSize: PAGE_SIZE,
        searchText,
        products: selectedOptions.map((p) => p.sellerSku),
        excludeInactive: true,
        shouldSearchByParentSku: true,
      })
    );
  }, [dispatch, mid, searchText]);

  useEffect(() => {
    if (mid) {
      fetchProducts();
    }
  }, [searchText, mid, fetchProducts]);

  useEffect(() => {
    if (!selectionInProgress) {
      setSelectedOptions(initProducts);
    }
  }, [selectionInProgress, initProducts]);

  const data = useMemo(
    () =>
      productsData.map((product) => ({
        productId: product.productId,
        productSku: product.productSku,
        sellerSku: product.sku,
        value: product.title,
        secondRowValue: `${marketplace === "amazon" ? "ASIN" : "LISTING ID"}: ${
          product.productSku
        } | SKU: ${product.sku}`,
        link: product.link,
        image: product.image,
        target: "_blank",
      })),
    [productsData, marketplace]
  );

  useEffect(() => {
    if (data.length > 0) {
      const productOptions = data.map((p) => {
        const found = selectedOptions.some(
          (sel) => sel.sellerSku === p.sellerSku
        );
        return { ...p, selected: found };
      });
      setOptions(productOptions);
    } else if (options.length) {
      setOptions([]);
    }
  }, [data, selectedOptions, options.length]);

  const toggleSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    option: ProductType
  ) => {
    setSelectionInProgress(true);
    const checked = e.target.checked;

    if (checked) {
      if (selectedOptions.length < MAX_PRODUCTS) {
        setSelectedOptions((prev) => [option, ...prev]);
      }
    } else {
      setSelectedOptions((prev) =>
        prev.filter((p) => p.sellerSku !== option.sellerSku)
      );
    }
  };

  const openPopover = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const closePopover = () => {
    onProductsChange(selectedOptions.map((p) => p.sellerSku));
    setSelectionInProgress(false);
    setSearchText("");
    setAnchorEl(null);
  };

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!setSelectAll) return;
    const checked = e.target.checked;
    setSelectAll(checked);

    if (checked) {
      const allProducts = options.map((o) => ({
        sellerSku: o.sellerSku,
      }));
      setSelectedOptions(allProducts.slice(0, MAX_PRODUCTS));
      setSelectionInProgress(true);
    } else {
      setSelectedOptions([]);
      setSelectionInProgress(true);
    }
  };

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid container direction="row" justifyContent="flex-end">
        <Box pt={1} pr={2}>
          {handleClearProducts && (
            <StyledTypography onClick={handleClearProducts}>
              {t("notifications.clear")}
            </StyledTypography>
          )}
        </Box>
        <DropDownBase onClick={openPopover} $width="50%">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid container alignItems="flex-end" item xs={8}>
              {isEmpty(selectedOptions) ? (
                <OptionText variant="body2">{title}</OptionText>
              ) : selectedOptions.length === 1 &&
                selectedOptions[0].sellerSku === "ALL" ? (
                <OptionText variant="body2" noWrap>
                  {t("notifications.all")}
                </OptionText>
              ) : (
                <OptionText variant="body2" noWrap>
                  {t("generic.selectedProductsCount", {
                    count: selectedOptions.length,
                  })}
                </OptionText>
              )}
            </Grid>
            <Grid item xs={2}>
              <ArrowDropDown />
            </Grid>
          </Grid>
        </DropDownBase>
      </Grid>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        marginThreshold={10}
        onClose={closePopover}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box p={1}>
              <SearchFilter setSearchText={setSearchText} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {productsFetching ? (
              <PanelLoading />
            ) : isEmpty(options) ? (
              <Typography align="center">
                {t("generic.noDataAvailableMessage")}
              </Typography>
            ) : (
              <>
                {setSelectAll && (
                  <MenuItem key="select-all" dense={true}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectAll}
                          size="small"
                          color="primary"
                          onChange={handleSelectAllChange}
                        />
                      }
                      label={t("generic.selectAll", { count: options.length })}
                    />
                  </MenuItem>
                )}
                {options.map((option) => (
                  <Box
                    key={option.sellerSku}
                    display="flex"
                    alignItems="center"
                    p={1}
                  >
                    <Checkbox
                      checked={option.selected}
                      size="small"
                      color="primary"
                      onChange={(e) => {
                        option.selected = e.target.checked;
                        toggleSelection(e, option);
                      }}
                    />
                    <LinkAndImageComponent {...option} />
                  </Box>
                ))}
              </>
            )}
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
});

export default ProductsSelector;
