import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTER,
} from "~/store/persistentAppSettings.redux";
import React, { memo } from "react";

import { CurrentStore } from "~/typedef/store";
import SalesByIntervalWidget from "../widgets/salesByInterval";
import { get } from "lodash";
import moment from "moment";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface SalesByIntervalWidgetProps {
  store: CurrentStore;
  condensed?: boolean;
}

const SalesByInterval = memo<SalesByIntervalWidgetProps>(
  function SalesByInterval({ store, condensed }) {
    const currentPeriod = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );
    const selectedTimezone = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentCompare = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const currentRange = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );
    const currentCurrency = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );
    const currentFilter = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentFilter") ||
        DEFAULT_FILTER
    );
    const includeTax = useTypedSelector((state) =>
      Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
    );

    return (
      <SalesByIntervalWidget
        store={store}
        currentPeriod={currentPeriod}
        currentRange={currentRange}
        currentCurrency={currentCurrency}
        currentFilter={currentFilter}
        condensed={condensed}
        timezone={selectedTimezone}
        includeTax={includeTax}
      />
    );
  }
);

export default SalesByInterval;
