import { Box, Grid } from "@material-ui/core";
import { Filter, Range } from "~/typedef/store";
import Panel, { FooterLink } from "~/components/panel/panel";
import React, { memo, useCallback, useMemo, useState } from "react";
import {
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";

import { Column } from "~/components/adTable/columnSelect";
import { CurrentStore } from "~/typedef/store";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import DownloadCsv from "../reportDownload/downloadCsv";
import NoScrollTable from "~/components/table/table";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import ProductsSelector from "~/components/select/productsSelector";
import Table from "~/components/table/table";
import { TextCell } from "~/components/table/cells/textCell";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { ValueCell } from "~/components/table/cells/valueCell";
import { formatCurrency } from "~/utils/currencyUtils";
import { getPercentageDifference } from "~/utils/salesUtils";
import moment from "moment";
import { numberWithCommas } from "~/utils/utils";
import { useSalesByPeriodQuery } from "@store/mystore/salesByPeriod.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface SalesByIntervalProps {
  store: CurrentStore;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  currentFilter?: Filter;
  footerLink?: FooterLink;
  condensed?: boolean;
  report?: boolean;
  pageSize?: number;
  timezone: string;
  includeTax: boolean;
  conditionalFormatting?: boolean;
}

const SalesByIntervalWidget = memo<SalesByIntervalProps>(
  function SalesByIntervalWidget({
    store,
    condensed,
    currentRange,
    currentCurrency,
    report,
    pageSize,
    includeTax,
    timezone,
  }) {
    const [selectedSkus, setSelectedSkus] = useState<string[]>([]);
    const CONDENSED_ROWS = 5;
    const { t } = useTranslation();
    const [intervalSortDesc, setIntervalSortDesc] = useState(true);
    const [selectAll, setSelectAll] = useState(false);

    const toggleIntervalSortDesc = () => {
      setIntervalSortDesc(!intervalSortDesc);
    };

    const currencyRates = useTypedSelector((state) =>
      state.globalVar ? state.globalVar.currencyRates : []
    );

    const exchangeRate = getExchangeRate(
      currencyRates,
      // In case of multi-channel, generic returns the data in AUD (DESTINATION_CURRENCY)
      getCurrencyByCountryCode[store.marketplaceCountry],
      currentCurrency
    );

    const handleClearProducts = useCallback(() => {
      setSelectedSkus([]);
      setSelectAll(false);
    }, []);

    const { salesByPeriod, currency, loading } = useSalesByPeriodQuery(
      {
        mid: store.merchantId,
        currentRange,
        marketplaceFilters: {
          marketplaceType: store.marketplace,
          marketplaceSubtype: store.marketplaceSubtype ?? store.marketplace,
        },
        includeTax,
        sellerSkus: selectedSkus,
      },
      {
        selectFromResult: ({ data, isFetching }) => {
          return {
            salesByPeriod: data?.chartData ?? [],
            currency: data?.currency,
            loading: isFetching,
          };
        },
      }
    );

    const salesPerformanceData = useMemo(() => {
      return (
        salesByPeriod?.map((row) => {
          const { current, prior } = row;
          const startDateStr = moment
            .unix(current.startTime)
            .tz(timezone)
            .format("D MMM YY");
          const endDataStr = moment
            .unix(current.endTime)
            .tz(timezone)
            .format("D MMM YY");
          const interval =
            startDateStr === endDataStr
              ? startDateStr
              : currentRange.interval === "w"
              ? `w/c. ${startDateStr}`
              : `${startDateStr} - ${endDataStr}`;
          return {
            interval,
            ...current,
            sales:
              current.sales != undefined
                ? formatCurrency(
                    current?.sales?.toFixed(2),
                    currencyRates,
                    currency,
                    currentCurrency
                  )
                : "-",
            avgLineItemValue:
              current.avgLineItemValue != undefined
                ? formatCurrency(
                    current?.avgLineItemValue?.toFixed(2),
                    currencyRates,
                    currency,
                    currentCurrency
                  )
                : "-",
            salesChange: {
              value:
                current.sales != undefined
                  ? formatCurrency(
                      current?.sales?.toFixed(2),
                      currencyRates,
                      currency,
                      currentCurrency
                    )
                  : "-",
              growth: prior?.sales
                ? getPercentageDifference(current.sales, prior.sales)
                : "N/A",
              hideValue: true,
            },
            lineItemsChange: {
              value:
                current.lineItemCount != undefined
                  ? numberWithCommas(current.lineItemCount)
                  : "-",
              growth: prior?.lineItemCount
                ? getPercentageDifference(
                    current.lineItemCount,
                    prior.lineItemCount
                  )
                : "N/A",
              hideValue: true,
            },
            unitsChange: {
              value:
                current.unitsSold != undefined
                  ? numberWithCommas(current.unitsSold)
                  : "-",
              growth: prior?.unitsSold
                ? getPercentageDifference(current.unitsSold, prior.unitsSold)
                : "N/A",
              hideValue: true,
            },
            avgLineItemValueChange: {
              value:
                current.avgLineItemValue != undefined
                  ? formatCurrency(
                      current?.avgLineItemValue?.toFixed(2),
                      currencyRates,
                      currency,
                      currentCurrency
                    )
                  : "-",
              growth: prior?.avgLineItemValue
                ? getPercentageDifference(
                    current.avgLineItemValue,
                    prior.avgLineItemValue
                  )
                : "N/A",
              hideValue: true,
            },
            ...(prior
              ? {
                  priorSales: formatCurrency(
                    prior?.sales?.toFixed(2),
                    currencyRates,
                    currency,
                    currentCurrency
                  ),
                  priorAvgLineItem: formatCurrency(
                    prior?.avgLineItemValue?.toFixed(2),
                    currencyRates,
                    currency,
                    currentCurrency
                  ),
                  priorUnits: numberWithCommas(prior?.unitsSold?.toFixed(0)),
                  priorLineItems: numberWithCommas(
                    prior?.lineItemCount?.toFixed(0)
                  ),
                }
              : {
                  priorAvgOrder: "N/A",
                  priorUnits: "N/A",
                  priorOrders: "N/A",
                  priorSales: "N/A",
                }),
          };
        }) || []
      );
    }, [salesByPeriod, currentRange, timezone, currencyRates, currentCurrency]);

    const salesPerformanceTableData = useMemo(() => {
      return intervalSortDesc
        ? salesPerformanceData.reverse()
        : salesPerformanceData;
    }, [intervalSortDesc, salesPerformanceData]);

    const columns: Column[] = useMemo(
      () => [
        {
          id: "timeIntervals",
          Header: `${t("myStoresWidget.salesByInterval.timeIntervals")} ${t(
            `generic.interval.${currentRange.interval}`
          )}`,
          accessor: "interval",
          Cell: TextCell,
          divideRight: true,
          customWidth: 250,
          isSorted: true,
          getSortDesc: () => {
            return intervalSortDesc;
          },
          onHeaderClick: () => {
            toggleIntervalSortDesc();
          },
        },
        {
          id: "sales",
          Header: t("myStoresWidget.salesByInterval.sales"),
          accessor: "sales",
          Cell: ValueCell,
          align: "right",
          customWidth: 180,
        },
        {
          id: "priorSales",
          Header: t("myStoresWidget.salesByInterval.comparison"),
          accessor: "priorSales",
          Cell: ValueCell,
          hiddenDown: report ? "md" : "sm",
          align: "right",
          customWidth: 180,
        },
        {
          id: "salesChange",
          Header: t("myStoresWidget.salesByInterval.percentChange"),
          accessor: "salesChange",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: report ? "md" : "sm",
          customWidth: 120,
          divideRight: true,
        },
        {
          id: "lineItems",
          Header: t("myStoresWidget.salesByInterval.lineItems"),
          accessor: "lineItemCount",
          Cell: ValueCell,
          align: "right",
          customWidth: 120,
        },
        {
          id: "priorLineItems",
          Header: t("myStoresWidget.salesByInterval.comparison"),
          accessor: "priorLineItems",
          Cell: ValueCell,
          hiddenDown: report ? "md" : "sm",
          align: "right",
          customWidth: 120,
        },
        {
          id: "lineItemsChange",
          Header: t("myStoresWidget.salesByInterval.percentChange"),
          accessor: "lineItemsChange",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: report ? "md" : "sm",
          customWidth: 120,
          divideRight: true,
        },
        {
          id: "units",
          Header: t("myStoresWidget.salesByInterval.units"),
          accessor: "unitsSold",
          Cell: ValueCell,
          align: "right",
          customWidth: 120,
        },
        {
          id: "priorUnits",
          Header: t("myStoresWidget.salesByInterval.comparison"),
          accessor: "priorUnits",
          Cell: ValueCell,
          hiddenDown: report ? "md" : "sm",
          align: "right",
          customWidth: 120,
        },
        {
          id: "unitsChange",
          Header: t("myStoresWidget.salesByInterval.percentChange"),
          accessor: "unitsChange",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: report ? "md" : "sm",
          customWidth: 120,
          divideRight: true,
        },
        {
          id: "avgLineItemValue",
          Header: t("myStoresWidget.salesByInterval.avgLineItemValue"),
          accessor: "avgLineItemValue",
          Cell: ValueCell,
          align: "right",
          customWidth: 120,
          hiddenDown: report ? "md" : "sm",
        },
        {
          id: "priorAvgLineItem",
          Header: t("myStoresWidget.salesByInterval.comparison"),
          accessor: "priorAvgLineItem",
          Cell: ValueCell,
          hiddenDown: report ? "md" : "sm",
          align: "right",
          customWidth: 120,
        },
        {
          id: "avgLineItemValueChange",
          Header: t("myStoresWidget.salesByInterval.percentChange"),
          accessor: "avgLineItemValueChange",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: report ? "md" : "sm",
          customWidth: 120,
        },
      ],
      [currentRange.interval, intervalSortDesc, t, report]
    );

    return (
      <Panel
        id="widget-sales-by-interval"
        title={t(`myStoresWidget.salesByInterval.mainTitle`)}
        actions={
          <>
            <Grid container item xs={5} md={12} direction="column">
              <ProductsSelector
                title={t("salesByInterval.selectProducts")}
                onProductsChange={setSelectedSkus}
                mid={store.merchantId}
                marketplace={store.marketplace}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                initProducts={selectedSkus.map((sellerSku) => ({
                  sellerSku,
                }))}
                handleClearProducts={handleClearProducts}
              />
            </Grid>
            {/* <DownloadCsv
              reportType="salesByInterval"
              path="/api/generic/salesByInterval"
              mid={store.merchantId}
              params={{
                mid: store.merchantId,
                currentRange,
                marketplaceFilters: {
                  marketplaceType: store.marketplace,
                  marketplaceSubtype:
                    store.marketplaceSubtype ?? store.marketplace,
                },
                includeTax,
                sellerSkus: selectedSkus,
                currency: currentCurrency,
                exchangeRate,
              }}
            /> */}
          </>
        }
        content={
          loading ? (
            <PanelLoading />
          ) : condensed ? (
            <NoScrollTable
              {...{
                columns: columns,
                data: salesPerformanceTableData.slice(0, CONDENSED_ROWS),
                pageSize,
                loading,
                isReport: report,
              }}
            />
          ) : (
            <Box>
              <Table
                {...{
                  columns: columns,
                  data: salesPerformanceTableData,
                  gridLayoutColumns: 12,
                  pageSize: pageSize ?? 50,
                  pagination: true,
                  isReport: report,
                  isManualSort: true,
                  sortBy: [
                    {
                      id: "timeIntervals",
                      desc: intervalSortDesc,
                    },
                  ],
                }}
              />
            </Box>
          )
        }
      />
    );
  }
);

export default SalesByIntervalWidget;
